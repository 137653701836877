
import React, { useState } from "react";
import axios from "axios";
// import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import globalAPIAddress from "../../GlobalPages/GlobalVar"

export default function OTPScreen() {
  const [otp, setOTP] = useState("");
  const navigate = useNavigate();

  const handleOTPChange = (event) => {
    setOTP(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${globalAPIAddress}/OTP`, {
        otp: otp,
      });
      alert(response.data.message);
      navigate('/');
    } catch (error) {
      console.error('Error:', error);
      alert("Failed to send OTP. Please try again later.");
    }
  };

  return (
    <div className="relative w-full h-screen" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg.jpeg)`}}>
      <div className="flex justify-center items-center h-full">
        <form className="text-left lg:w-[400px] w-full mx-auto bg-white p-8 rounded-lg shadow-lg md:shadow-md" onSubmit={handleSubmit}>
          <h2 className="text-2xl text-center dark:text-black mb-6 border-b-2 relative border-gray-200 pb-3">
            QUICK <span className="font-bold">TRANSPORT </span>LINE
          </h2>
          <h2 className="text-xl text-center relative pb-8 px-1.5 mt-10 dark:text-gray-600 ">
            OTP Verification
          </h2>
          <div className="flex flex-col text-black py-2">
            <label className="relative text-gray-600 px-2">OTP</label>
            <input
              className="rounded-lg border relative bg-gray-100 p-2 mt-2 focus:bg-gray-200"
              type="text"
              value={otp}
              onChange={handleOTPChange}
              placeholder="Enter 6-digit OTP"
              maxLength={6}
            />
          </div>
          <button
            type="submit"
            className="rounded-lg w-full py-2 mt-8 bg-red-500 hover:bg-yellow-500 font-semibold relative text-white"
          >
            Verify OTP
          </button>
          <p className="text-center mt-3 relative text-gray-500">
            Resend OTP?{" "}
            <button className="relative text-red-500">Resend</button>
          </p>
        </form>
      </div>
    </div>
  );
}
