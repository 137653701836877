import React from "react";

export default function VehicleListings({ left }) {
  const totalDays = 365;

  const percentage = (left / totalDays) * 100;

  const progressBarStyles = {
    position: "relative",
    width: "100%",
    height: "30px",
    backgroundColor: "#ccc",
    borderRadius: "3px",
  };

  const innerBarStyles = {
    width: `${percentage}%`,
    height: "100%",
    backgroundColor: "blue",
    borderRadius: "3px",
    position: "absolute",
    top: 0,
    left: 0,
  };

  const daysLeftStyles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "white",
    fontSize: "1.1rem",
    fontWeight: "bold",
    zIndex: 1, // Ensure text is on top of the inner bar
  };
  return (
    <div className="progress-bar-container">
      <div
        className="progress-bar text-center text-lg text-white"
        style={progressBarStyles}
      >
        <div style={innerBarStyles}></div>
        <span style={daysLeftStyles}>{left} Posts left</span>
      </div>
    </div>
  );
}
