// UserProfileIcon.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

const UserProfileIcon = () => {
  return (
    <div
      style={{
        width: '22px',
        height: '22px',
        borderRadius: '50%',
        backgroundColor: 'red',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        fontSize: '10px',
        cursor: 'pointer', // Add a pointer cursor for interaction
      }}
    >
      <FontAwesomeIcon icon={faUser} />
    </div>
  );
};

export default UserProfileIcon;
