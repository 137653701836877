import React from "react";
import Interface from "../../Rcompo/Interface";

export default function Contracts() {
  return (
    <Interface>
      <div className="px-1 lg:px-8 mx-7 md:mx-10   lg:mx-4">
        <div className="text-3xl font-medium mt-6 mb-4 ml-8 font-sans">
          <span>Contract</span>
        </div>
        <hr className="mb-6 mx-8"></hr>
        <div className="border  w-full h-fit mt-6  bg-white  shadow-2xl rounded-tr-xl rounded-tl-xl">
          <div
            style={{ backgroundColor: "#F5F5F5" }}
            className="text-xl font-medium h-fit flex justify-between px-4 py-2 mb-4 bg-slate-300  rounded-tr-xl rounded-tl-xl"
          >
            <div>
              <p className="mx-4">Make Contract</p>
            </div>
          </div>
          <div className="px-4 py-4">
            <p>
              Contract If you post vehicles for shipment and have a pre-existing
              dispatch contract that you would like to use with your Central
              Dispatch dispatch sheets, you may copy and paste it below. Once
              you have added your contract, each carrier will be required to
              sign your contract at the same time they sign the dispatch sheet.
              Please Note: Modifying your contract will NOT modify it for any
              dispatches that have been previously signed by the carrier.
            </p>
            <div>
              <textarea className="h-80 w-full py-2 px-2 my-5 border-2 border-gray-300 focus:outline-none focus:ring focus:border-blue-500" />
            </div>
            <div className="flex justify-end">
              <button className="bg-red-500 hover:bg-red-600 text-white font-bold py-4 px-6 rounded whitespace-nowrap">
                Submit Contract
              </button>
            </div>
          </div>
        </div>
      </div>
    </Interface>
  );
}
