import React, { useState } from "react";
import Interface from "../../Rcompo/Interface";
import DriverInterface from "../../Rcompo/DriverInterface";
export default function InterestedShipper() {
  const handleIconClick = (icon) => {
    alert(`Clicked ${icon} button`);
  };
  const handleIconClick1 = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setCallNowVisible(!isCallNowVisible);
  };
  const [activeLink, setActiveLink] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isCallNowVisible, setCallNowVisible] = useState(true);
  const arr = [1, 2, 3, 4, 5];
  return (
    <DriverInterface>
      <div>
        <div className="h-full ">
          <div className=" mt-14 mb-10 shadow-2xl shadow-gray-300 mx-10 px-6 bg-white rounded-lg py-4">
            <div className="    ">
              <span className="font-semibold text-xl">
                All Available Listings (0)
              </span>
              <div className="Flex mt-5 ">
                {/* Flex */}
                <div className="pl-6 font-sm flex items-center">
                  <span>Sort by</span>
                  {/* Sort By  */}
                  <button
                    onClick={() => handleIconClick("Pick-Up Location")}
                    className="ml-2  flex items-center px-2 py-0 bg-gray-300 rounded "
                  >
                    Location
                    <img
                      src={process.env.PUBLIC_URL + "/images/down-arrow.png"}
                      alt="Image1"
                      className="w-3 h-3 ml-1"
                    />
                    {/* button Pickup */}
                  </button>

                  <div className=" ml-2">
                    <button
                      onClick={() => handleIconClick("Up Arrow")}
                      className="icon-button"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL + "/images/up-arrow-white.png"
                        }
                        alt="Image1"
                        className="w-5 h-5 ml-1  rounded-full bg-red-500 color-white"
                      />
                    </button>
                  </div>

                  <div className="ml-1">
                    <button
                      onClick={() => handleIconClick("Down Arrow")}
                      className="icon-button"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL + "/images/down-arrow-red.png"
                        }
                        alt="Image1"
                        className="w-5 h-5 ml-1"
                      />
                    </button>
                    {/* Arrows with the Images and Background */}
                  </div>
                  <div className="pl-6 font-sm flex items-center">
                    <span>then by</span>
                    {/* Sort By  */}
                    <button
                      onClick={() => handleIconClick("Delivery Metro aria")}
                      className="ml-2 flex items-center px-2 py-0 bg-gray-300 rounded "
                    >
                      Delivery Metro aria
                      <img
                        src={process.env.PUBLIC_URL + "/images/down-arrow.png"}
                        alt="Image1"
                        className="w-3 h-3 ml-1"
                      />
                      {/* button Pickup */}
                    </button>
                    {/* Delivery Metro aria Button and Then By */}
                  </div>
                  {/* Up Arrow ANd Down Arrow After the Aria */}
                  <div className=" ml-2">
                    <button
                      onClick={() => handleIconClick("Up Arrow")}
                      className="icon-button"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL + "/images/up-arrow-white.png"
                        }
                        alt="Image1"
                        className="w-5 h-5 ml-1  rounded-full bg-red-500 color-white"
                      />
                    </button>
                  </div>

                  <div className="ml-1">
                    <button
                      onClick={() => handleIconClick("Down Arrow")}
                      className="icon-button"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL + "/images/down-arrow-red.png"
                        }
                        alt="Image1"
                        className="w-5 h-5 ml-1"
                      />
                    </button>
                    {/* Arrows with the Images and Background */}
                  </div>
                  {/* Up Arrow ANd Down Arrow After the Aria */}

                  <div className="pl-12 font-sm flex items-center">
                    <span>View</span>
                    {/* View */}
                    <a>
                      <img
                        src={process.env.PUBLIC_URL + "/images/grid-red.png"}
                        alt="Image1"
                        className="w-6 h-6 ml-1"
                      />
                    </a>
                    {/* Grid Image*/}
                    <a>
                      <img
                        src={process.env.PUBLIC_URL + "/images/brochure.png"}
                        alt="Image1"
                        className="w-5 h-5 ml-1"
                      />
                    </a>
                    {/* 2nd Grid Image*/}
                  </div>

                  <div className="pl-28 font-sm flex items-center">
                    <span>Per Page</span>
                    {/* Per Page */}
                    <button className="ml-2 flex items-center px-1 py-0 bg-gray-300 rounded ">
                      50
                      <img
                        src={process.env.PUBLIC_URL + "/images/down-arrow.png"}
                        alt="Image1"
                        className="w-3 h-3 ml-1"
                      />
                      {/* button Pickup */}
                    </button>
                    {/* Per Page */}
                  </div>
                </div>
                {/* Flex */}
              </div>
            </div>
          </div>
          {/* popup container */}
          {arr.map((opt, index) => (
            <div
              className={
                activeLink === true
                  ? "mb-16 h-fit rounded-lg overflow-hidden outer-container shadow-lg mx-10 pt-8 border-black border bg-white"
                  : "bg-white mb-16 h-56 rounded-lg pt-8 overflow-hidden shadow-lg mx-10"
              }
            >
              {/* Miles Amount Div Dummy */}
              <div className="flex">
                <div className="mt-14 ml-6 ">
                  <span className="text-5xl font-bold">$00.00</span>
                  <br></br>
                  <span className="text-slate-400">0000 mi @ $0.00/mi</span>
                  <br></br>
                  <span className="text-slate-400">Cash/Certified</span>
                  {/* Miles Amount Div Dummy */}
                </div>
                {/* Vehicle Info */}
                <div className="ml-12 mt-8">
                  <span className="text-slate-400">Vehicle Info</span>
                  <br></br>
                  <span className="">/--/--/--/</span>
                </div>
                {/* Vehicle Info */}

                {/* Company Details And Ratings */}
                <div className="ml-12 mt-8">
                  <span className="text-slate-400">Company</span>
                  <br></br>
                  <span className="text-red-600">/--/--/--/</span>
                </div>
                {/* Company Details And Ratings */}

                {/* PickUp-Location And Delivery Location And View Route */}
                <div className="ml-12 mt-8">
                  <span className="text-slate-400">PickUp-Location</span>
                  <br></br>
                  <span className="text-red-600">/--/--/--/</span>
                  <br></br>
                  <span className="text-slate-400">Delivery-Location</span>
                  <br></br>
                  <span className="text-red-600">/--/--/--/</span>
                  <br></br>
                  <span className="text-red-600 mt-2 font-bold">
                    View Route
                  </span>
                  {/* PickUp-Location And Delivery Location And View Route */}
                </div>

                <div className="ml-12 mt-8">
                  <span className="text-slate-400">
                    PickUp on or After Date
                  </span>
                  <br></br>
                  <span className="text-red-600">/--/--/--/</span>
                  <br></br>
                  <span className="text-slate-400">Desired Delivery Date</span>
                  <br></br>
                  <span className="text-red-600">/--/--/--/</span>
                  <br></br>
                  <span className="text-slate-400">Posted Date</span>
                  {/* PickUp-Location And Delivery Location And Posted Date*/}
                </div>
                {/* Call Now Button */}
                {/* <div className='ml-6 mt-20'>
            {isCallNowVisible && (
              <button onClick={() => handleIconClick('Pick-Up Location')} className="ml-2 flex items-center px-2 py-0 bg-red-600 rounded ">
                <span className='text-white'>Call Now</span>

              </button>
            )}
            </div> */}
                {/* Call Now Button */}

                {/* Up Arrow ANd Down Arrow After Call Now*/}
                <div className="ml-8 mt-20">
                  <button
                    onClick={() => {
                      handleIconClick1();
                      setActiveLink(!activeLink);
                    }}
                    className="icon-button"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/up-arrow-white.png"
                      }
                      alt="Image1"
                      className="w-5 h-5 ml-1  rounded-full bg-red-500 color-white"
                    />
                  </button>
                </div>
              </div>
              {isDropdownOpen && (
                <div className="mt-10 mb-6 inner-container bg-white">
                  <div className="h-fit border-t-2  border-gray-200 mx-10 pt-8">
                    <div className="flex">
                      {/* Flex */}
                      <div className="ml-8 mt-4">
                        {/* GENERAL INFO Button */}
                        <button
                          onClick={() => handleIconClick("General Info")}
                          className="ml-2  px-3 py-1 bg-red-600 rounded "
                        >
                          <span className="text-white">GENERAL INFO</span>
                        </button>
                        {/* GENERAL INFO Button */}
                      </div>
                      <div className="ml-8 mt-4">
                        {/* PRICE COMPARE Button */}
                        <button
                          onClick={() => handleIconClick("General Info")}
                          className="ml-2  px-3 py-1 bg-gray-100 rounded "
                        >
                          <span className="">PRICE COMPARE</span>
                        </button>
                        {/* PRICE COMPARE Button */}
                      </div>
                      <div className="ml-8 mt-4">
                        {/* GENERAL INFO Button */}
                        <button
                          onClick={() => handleIconClick("General Info")}
                          className="ml-96 content-end px-3 py-1 bg-red-600 rounded "
                        >
                          <span className="text-white">CALL NOW</span>
                        </button>
                        {/* GENERAL INFO Button */}
                      </div>
                      {/* display Flex: */}
                    </div>
                    {/* VEHICLE INFO IN DETAILS */}
                    <div className="flex">
                      <div className="mt-8 ml-10">
                        <span className="">VEHICLE INFO</span>
                        <br></br>
                        <div className="mt-4">
                          {/* Vehicle Info 1st Vehicle Name */}
                          <span className="text-slate-400">Vehicle</span>
                          <br></br>
                          <span className="text-slate-400">//--//--//</span>
                          {/* Vehicle Info 1st Vehicle Name */}
                        </div>
                        <div className="mt-4">
                          {/* Vehicle Info 1st Vehicle Name */}
                          <span className="text-slate-400">Weight</span>
                          <br></br>
                          <span>Unspecified</span>
                          {/* Vehicle Info 1st Vehicle Name */}
                        </div>
                        <div className="mt-4">
                          {/* Vehicle Info 1st Vehicle Name */}
                          <span className="text-slate-400">Dimensions</span>
                          <br></br>
                          <span>Unspecified</span>
                          {/* Vehicle Info 1st Vehicle Name */}
                        </div>

                        <div className="mt-4 mb-4">
                          {/* Company Info 1st Company Name */}
                          <span className="text-slate-400">
                            Important Notes
                          </span>
                          <br></br>
                          <span className="text-red-600">Inoperable</span>
                        </div>
                      </div>
                      {/* VEHICLE INFO IN DETAILS */}

                      {/* COMPANY INFO IN DETAILS */}
                      <div className="mt-8 ml-20">
                        <span className="">COMPANY INFO</span>
                        <br></br>
                        <div className="mt-4">
                          <span className="text-slate-400">Company Name</span>
                          <br></br>
                          <span className="text-red-600">//--//--//</span>
                        </div>
                        <div className="mt-4">
                          <span className="text-slate-400">Phone Number</span>
                          <br></br>
                          <span className="text-red-600">00-00000-00</span>
                        </div>
                        <div className="mt-4">
                          <span className="text-slate-400">Hours</span>
                          <br></br>
                          <span className="text-red-600">GMT 5</span>
                        </div>
                        <div className="mt-4">
                          <span className="text-slate-400">
                            Rating In Old System
                          </span>
                          <br></br>
                          <span>0%</span>
                          {/* Vehicle Info 1st Vehicle Name */}
                        </div>

                        <div className="mt-4 mb-4">
                          {/* Vehicle Info 1st Vehicle Name */}
                          <span className="text-slate-400">
                            Overall Ratings
                          </span>
                          <br></br>
                          <span className="">5.0</span>
                        </div>
                      </div>
                      {/* COMPANY INFO IN DETAILS */}

                      {/* JOB INFO IN DETAILS */}
                      <div className="mt-8 ml-20">
                        <span className="">JOB INFO</span>
                        <br></br>
                        <div className="mt-4">
                          <span className="text-slate-400">Origin</span>
                          <br></br>
                          <span className="text-red-600">//--//--//</span>
                          {/* Vehicle Info 1st Vehicle Name */}
                        </div>
                        <div className="mt-4">
                          {/* Vehicle Info 1st Vehicle Name */}
                          <span className="text-slate-400">Destination</span>
                          <br></br>
                          <span className="text-red-600">Unspecified</span>
                          {/* Vehicle Info 1st Vehicle Name */}
                        </div>
                        <div className="mt-4">
                          {/* Vehicle Info 1st Vehicle Name */}
                          <span className="text-slate-400">Price</span>
                          <br></br>
                          <span className="">00.00$ @ 0.93mi</span>
                          {/* Vehicle Info 1st Vehicle Name */}
                        </div>

                        <div className="mt-4">
                          {/* Vehicle Info 1st Vehicle Name */}
                          <span className="text-slate-400">Order ID</span>
                          <br></br>
                          <span>000-00</span>
                        </div>
                      </div>
                      {/* JOB INFO IN DETAILS */}

                      {/* DATES IN DETAILS */}
                      <div className="mt-8 ml-20">
                        <span className="">DATES</span>
                        <br></br>
                        <div className="mt-4">
                          <span className="text-slate-400">
                            Pick-up on or After Date
                          </span>
                          <br></br>
                          <span className=" font-bold">DD/MM/YYYY</span>
                          {/* Vehicle Info 1st Vehicle Name */}
                        </div>
                        <div className="mt-4">
                          {/* Vehicle Info 1st Vehicle Name */}
                          <span className="text-slate-400">
                            Desired Delivery Date
                          </span>
                          <br></br>
                          <span className="font-bold">DD/MM/YYYY</span>
                          {/* Vehicle Info 1st Vehicle Name */}
                        </div>
                        <div className="mt-4">
                          {/* Vehicle Info 1st Vehicle Name */}
                          <span className="text-slate-400">Posted Date</span>
                          <br></br>
                          <span className="">DD/MM/YYYY</span>
                          {/* Vehicle Info 1st Vehicle Name */}
                        </div>
                        {/* DATES IN DETAILS */}
                      </div>
                      {/* MISC IN DETAILS */}
                      <div className="mt-8 ml-12">
                        <span className="">MISC</span>
                        <br></br>
                        <div className="mt-4">
                          {/* Vehicle Info 1st Vehicle Name */}
                          <span className="text-slate-400">
                            Additional Info
                          </span>
                          <br></br>
                          <span className=" font-bold">Abcd</span>
                        </div>
                        {/* MISC IN DETAILS */}
                      </div>
                      {/* Flex for Lower Content */}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </DriverInterface>
  );
}
