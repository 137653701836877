import React from "react";
import Interface from "../../Rcompo/Interface";

export default function ShiperDashboard() {
  return (
    <Interface>
      <div className="hidden lg:flex">
        <img
          src={process.env.PUBLIC_URL + "/images/Dashboardbg.png"}
          className="h-screen w-full"
        />
      </div>

      <div className="flex lg:hidden">
        <img
          src={process.env.PUBLIC_URL + "/images/Dashboardbgunsplash.png"}
          className="h-full w-full"
        />
      </div>
      {/* <div className="absolute top-96 left-10 lg:top-80 lg:left-32">
        <span className="text-2xl lg:text-5xl font-semibold text-white font-sans">
          Welcome to Quick <br />
          Transport Line
        </span>
      </div> */}
    </Interface>
  );
}
