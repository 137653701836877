import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import globalAPIAddress from "../../GlobalPages/GlobalVar"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ChangePassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.warn("Passwords do not match.");
      console.log("ansjdjd")
      return;
    }
    try {
      const response = await axios.post(`${globalAPIAddress}/Change-Password`, {
        password: password,
      });
      if (response.data.status === "otpSent") {
        navigate('/'); 
      } 
      else {
        toast.error('Something went wrong!');
      }

    } catch (error) {
      if (error.response) {
          toast.warn(error.response.data.errors[0].msg)
           console.log(error.response.data); 
        } else if (error.request) {
          console.log("shariq")
          console.log(error.request);
        } else {
          console.log("adel")
          console.log('Error', error.message);
        }
        console.log(error.config);
    }
  };
  
  return (
    <div className="relative w-full h-screen" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg.jpeg)`}}>
      <div className="flex justify-center items-center h-full">
   <form className="text-left lg:w-[400px] w-full mx-auto bg-white p-8 rounded-lg shadow-lg md:shadow-md" onSubmit={handleSubmit}>
   <ToastContainer position="top-center" reverseOrder={false} autoClose={5000}/>
     
          <h2 className="text-2xl text-center dark:text-black mb-6 border-b-2 relative border-gray-200 pb-3">
            QUICK <span className="font-bold">TRANSPORT </span>LINE
          </h2>
          <h2 className="text-xl text-center relative pb-8 px-1.5 mt-10 dark:text-gray-600 ">
            Forget Password
          </h2>
          <div className="flex flex-col text-black py-2">
            <label className="relative text-gray-600 px-2">Password</label>
            <input
              className="rounded-lg border relative bg-gray-100 p-2 mt-2 focus:bg-gray-200"
              type="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder="Enter Your New Password"
            />
          </div>
          <div className="flex flex-col text-black py-2">
            <label className="relative text-gray-600 px-2">Confirm Password</label>
            <input
              className="rounded-lg border relative bg-gray-100 p-2 mt-2 focus:bg-gray-200"
              type="password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              placeholder="Confirm Your New Password"
            />
          </div>
          <button
            type="submit"
            className="rounded-lg w-full py-2 mt-8 bg-red-500 hover:bg-yellow-500 font-semibold relative text-white"
          >
            Reset Password
          </button>
          <p className="text-center mt-3 relative text-gray-500">
            Remember your password?{" "}
            <Link to="/">
              <button className="relative text-red-500">Sign In</button>
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
}
