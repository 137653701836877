import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import globalAPIAddress from "../../GlobalPages/GlobalVar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function LoginSignup() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const navigate = useNavigate();

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const HandleonClick = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${globalAPIAddress}/`, {
        username: username,
        password: password,
      });
      console.log('Response:', response);
      const { dashboardRoute } = response.data;
      if (dashboardRoute === "Interface") {
        setTimeout(() => {
          toast.success("Login SuccessFul");
          navigate('/Interface');
        }, 1000); 
      } else if (dashboardRoute === "DriverDashboard") {
        setTimeout(() => {
          toast.success("Login SuccessFul");
          navigate('/DriverDashboard');
        }, 1000); 
      } 
    } catch (error) {
      // setInvalidCredentials(true);
      toast.warn("Please enter valid credentials");
      console.error('Error:', error);
      console.log("Abcdsskk")
    }
  };

  return (
    <div className="relative w-full h-screen" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg.jpeg)` }}>
      <div className="flex justify-center items-center h-full">
        <form className="text-left lg:w-[400px] w-full mx-auto bg-white p-8 rounded-lg shadow-lg md:shadow-md" onSubmit={HandleonClick}>
          <ToastContainer position="top-center" reverseOrder={false} autoClose={3000}/>
          <h2 className="text-2xl text-center dark:text-black mb-6 border-b-2 relative border-gray-200 pb-3">
            QUICK <span className="font-bold">TRANSPORT </span>LINE
          </h2>
          <h2 className="text-3xl relative pb-8 px-1.5 mt-10 dark:text-gray-600 font-semibold">Sign in</h2>
          <div className={`flex flex-col text-black py-2 ${invalidCredentials ? 'border border-red-500' : ''}`}>
            <label className="relative text-gray-600 px-2">User Name</label>
            <input
              className="rounded-lg border relative bg-gray-100 p-2 mt-2 focus:bg-gray-200"
              type="text"
              onChange={handleUsernameChange}
            />
          </div>
          <div className={`flex flex-col text-black py-2 ${invalidCredentials ? 'border border-red-500' : ''}`}>
            <label className="relative text-gray-600 px-2">Password</label>
            <input
              className="rounded-lg border relative bg-gray-100 mt-2 p-2 focus:bg-gray-200"
              type="password"
              onChange={handlePasswordChange}
            />
          </div>
          <div className="flex items-end">
            <label className="text-blue-700 mx-auto flex justify-end">
              <div className="flex text-end" >
                <Link to="/ForgetPassword">
                  <button>Forget Password?</button>
                </Link>
              </div>   
            </label>
          </div>
          <button
            type="submit"
            className="rounded-lg w-full py-2 mt-8 bg-red-500 hover:bg-yellow-500 font-semibold relative text-white"
          >
            Submit
          </button>
          <p className="text-center mt-3 relative text-gray-500">
            Don't have an account?{" "}
            <Link to="/Signup">
              <button className="relative text-red-500">Sign Up</button>
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
}
