import React, { useState } from "react";
import Interface from "../../Rcompo/Interface";

export default function Network() {
  const [activeTab, setActiveTab] = useState("preferred");
  return (
    <Interface>
      <>
        <div className="lg:px-8">
          <div className="text-xl sm:text-xl lg:text-3xl mt-6 mb-4 ml-8">
            Network
          </div>
          <div className="border w-full h-fit mt-6 bg-white shadow-2xl rounded-tr-xl rounded-tl-xl">
            <div
              style={{ backgroundColor: "#F5F5F5" }}
              className="text-sm md:text-xl sm:text-lg font-semibold flex justify-between px-4 py-2 mb-4 bg-slate-300 rounded-tr-xl rounded-tl-xl"
            >
              <div>
                <button
                  className={`text-black py-2 pl-2 pr-2 ${
                    activeTab === "preferred" ? "bg-gray-300" : ""
                  }`}
                  onClick={() => setActiveTab("preferred")}
                >
                  Preferred Partner
                </button>
                <button
                  className={`text-black py-2 px-4 ${
                    activeTab === "blocklist" ? "bg-gray-300" : ""
                  }`}
                  onClick={() => setActiveTab("blocklist")}
                >
                  Blocklist
                </button>
              </div>
            </div>
            <div className="flex justify-between font-bold mb-4">
              <div className="ml-6">Company</div>
              <div className="ml-16 lg:ml-24">Type Of Business</div>
              <div className="mr-12">Actions</div>
            </div>
            {activeTab === "preferred" && (
              <>
                <div className="mb-8">
                  <p className="font-bold text-center">
                    No Partner Added No User in the List
                  </p>
                </div>
              </>
            )}
            {activeTab === "blocklist" && (
              <>
                <div className="flex justify-between mb-4">
                  <div className="ml-6 text-blue-500 w-32">
                    <button className="hover:underline">Almageo Corp</button>
                  </div>
                  <div>Carrier</div>
                  <div className="mr-12">
                    <button className="bg-black text-white px-2">Remove</button>
                  </div>
                </div>

                <div className="flex justify-between mb-4">
                  <div className="ml-6 text-blue-500 w-32">
                    <button className="hover:underline">Alm Corp</button>
                  </div>
                  <div>Carrier</div>
                  <div className="mr-12">
                    <button className="bg-black text-white px-2">Remove</button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    </Interface>
  );
}
